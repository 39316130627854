@import './vars';


.banner-image-controller{
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 4.8;
}

.banner-image-controller-mobile{
  width: 100%;
  height: 230px;
  object-fit: cover;
}

.absolute-container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1, h2, h3, h4, h5, h6, div{
      color: white !important;
      text-align: center;
    }
}

.empty-control{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
}

.banner-image {
  position: relative;
  width: 100%;
  // margin-bottom: -60px;
}

.loading-controler{
  padding: 20px 0px;
}
@import './vars';


.banner-image-controller{
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 3.5;
}

.banner-image-controller-mobile{
  width: 100%;
  height: 230px;
  object-fit: cover;
}

.absolute-container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1, h2, h3, h4, h5, h6, div{
      color: white !important;
      text-align: center;
    }
}

.empty-control{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
}

.banner-image {
  position: relative;
  width: 100%;
  // margin-bottom: -60px;
}

.loading-controler{
  padding: 20px 0px;
}

.search-property {
  background-color: white;
  padding: 12px;
  color: $primary !important;
  width: calc(100%);
  border-radius: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $smWidthBreakpoint) {
    padding: 6px;
    padding-right: 10px;
  }


  span {
    // color: $primary !important;
  }

  button {
    min-width: 100px;
    @media screen and (min-width: $phoneWidthBreakpoint) {
      min-width: 160px;
      height: 48px !important;
    }
  }
}

.max-width-controller {
  // background-color: $primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.landing-layout {
  padding-top: 9%;
  color: white;
  width: 100%;
  display: grid;
  flex-direction: column;
  justify-content: center;

  h1 {
    color: white;
    font-weight: 500;
    white-space: pre-line;
    display: inline;
    line-height: 56px;
    font-size: 48px;

    @media screen and (min-width: $smWidthBreakpoint) and (max-width: $ipadWidthBreakpoint) {
      font-size: 38px;
      line-height: 48px;
    }
  }

  @media screen and (max-width: $smWidthBreakpoint) {
    // height: 320px !important;
    font-size: 22px;
    line-height: 36px;

    h1 {
      font-size:22px;
      line-height: 37px;
    }
  }
}

.underline {
  border-bottom: 3px solid $selectedGreen;
}

.pt28{
  // margin-top: 38%!important;
}
.headings {
  // max-width: 600px;

  @media screen and (min-width: $smWidthBreakpoint) and (max-width: $ipadWidthBreakpoint) {
    max-width: 450px;
  }
}

.search-controller{
  width: 100%;
  max-width: 600px;

  @media screen and (min-width: $smWidthBreakpoint) and (max-width: $ipadWidthBreakpoint) {
    max-width: 500px;
  }
}

.headings, .search-controller {
  z-index: 10;
}

.landing-image {

  position: absolute;
  // right: 20px;
  top: 10%;
  transform: translate(0, 0%);

  z-index: 9;
  width: 100%;

  @media screen and (max-width: $smWidthBreakpoint) {
    // display: none;
  }
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

h1 {
  font-size: 16px;
  margin: 5px;
}
.container {
  width: 100%;
  height: 92vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f9fafc;
}

/*
Payment Information card
*/
.details_card {
/*   width: 700px;
  height: 400px; */
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
}

/*
Order Summary Card
*/
.summary_card {
  background-color: #fff;
/*   width: 350px; */
  height: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
}

/*
Order Card Item Card
*/
.card_item {
  display: flex;
  position: relative;
  margin: 10px 0;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 5px;
}

.card_item .product_img img {
  height: 80px;
  margin-right: 20px;
  width: 70px;
  border-radius: 5px;
}

.product_info h1 {
  font-size: 20px;
  color: #1e212d;
  margin: 5px 0;
}

.product_info p {
  color: #a1a1a1;
  font-size: 14px;
}
.product_rate_info {
  display: flex;
  margin: 5px 0;
  align-items: center;
  justify-content: space-between;
}

.pqt {
  font-size: 20px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}


.pqt-plus,
.pqt-minus {
  background: #fcfcfc;
  border: none;
  font-size: 20px;
  height: 100%;
  padding: 0 15px;
}
.pqt-plus:hover,
.pqt-minus:hover {
  background: #53b5aa;
  color: #fff;
  cursor: pointer;
}

.pqt-plus {
  line-height: 30px;
}

.pqt-minus {
  line-height: 30px;
}
/*
Order price & total
*/
.order_price,
.order_service,
.order_total {
  display: flex;

  justify-content: space-between;
  padding: 10px;
}
.order_price p,
.order_service p {
  color: #a1a1a1;
}

.order_total p {
  font-weight: 600;
}

/*
Payment Information all input
*/

input {
  outline: 0;
  background: #f2f2f2;
  border-radius: 4px;
  width: 100%;
  border: 0;
  caret-color: #4f5d7e;
  margin: 10px 15px;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 14px;
}

.first_lastName,
.shipping_card,
.address {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.new_card {
  width: 100%;
  height: 100px;
  border: 2px solid #53b5aa;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.add_savedcard {
  width: 100%;
  height: 100px;
  border: 2px solid #a1a1a1;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}
/*
Procced Payment button
*/
.proced_payment a {
  padding: 10px 20px;
  width: 200px;
  border: 2px solid #f5f5f5;
  background-color: #53b5aa;
  color: #000;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
}

footer {
  background-color: #000;
  color: #fff;
  height: 50px;
  text-align: center;
}
footer a {
  text-decoration: none;
  color: #fff;
}

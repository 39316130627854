@import './vars';
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:100,300,500,400,600,700,800');


html,
body,
#root,
#root > * {
  height: 100%;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

#searchForm > .ant-row > .ant-row.ant-form-item {
  width: 100%;
  text-align: right;
  padding-right: 12px;
  .ant-col {
    flex: 1;
    max-width: 100%;
    width: 100%;
    .ant-btn:first-child {
      margin-right: 10px;
    }
  }
}

.app-loading-wrapper.ant-spin-spinning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(256, 256, 256, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.layout-page-content-style{
  min-height: 100vh;
  width: 100%;
  padding-bottom: 80px;
}

.scroller {
  overflow: auto;
  height: 1000px;
}

.scroller-timeline{
  overflow: overlay;
  height: 100%;
  max-height: 600px;
}

.row-dark{
  background-color: $grey;
  font-weight: 600;
}

.space-sb-c-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dropdown-close {
  color: black;
}

.scroll-document {
  overflow-y: overlay;
  height: 100%;
  max-height: 600px;
}

.scroll-document::-webkit-scrollbar {
  background-color: #f8f8f8;
  width: 8px;
  border-radius: 20px;
}

.scroll-document::-webkit-scrollbar-thumb{
  background-color: $grey;
  border-radius: 20px;
}

.scroller-timeline::-webkit-scrollbar {
  background-color: #f8f8f8;
  width: 8px;
  border-radius: 20px;
}

.scroller-timeline::-webkit-scrollbar-thumb{
  background-color: $grey;
  border-radius: 20px;
}

.no-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mobile-drawer {
}
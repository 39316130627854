@import './vars';

.ant-list-item {
  padding: 12px 12px !important;
}

.ant-table-cell .ant-btn.ant-btn-link {
  padding: 0 4px !important;
}

.ant-btn + .ant-btn {
  margin-left: 10px;
}

.ant-input {
  height: 48px;
}

.ant-input-affix-wrapper {
  height: 48px;

  .ant-input {
    height: auto;
  }
}

.ant-btn-primary {
  background-color: $buttonGreen !important;
}

.ant-btn-primary:disabled{
  background-color: #F5F5F5 !important;
  color: #B8B8B8 !important;
}

.ant-row-flex, .ant-row {
  margin: 0 !important;
}


.ant-btn-background-ghost {
  background-color: transparent !important;
  color: black !important;
}

.ant-picker {
  height: 48px;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-tabs-ink-bar {
  background-color: $selectedGreen !important;
}

.ant-menu-item-selected::after {
  border-bottom-width: 4px !important;
  border-bottom-color: white !important;
}


//AntD <Select /> Overide Styles Start

.ant-dropdown-reset .ant-select-selector{
  background-color: #f3f6f9 !important;
  border: 0 !important;
  height: 48px !important;
  align-items: center;
}

.ant-dropdown-reset-2 .ant-select-selector{
  background-color: #f3f6f9 !important;
  border: 0 !important;
  height: 50px !important;
  align-items: center;
}

.ant-select-item-option-selected {
  color: white !important;
  font-weight: normal !important;
}

.ant-dropdown-reset-2 .ant-select-selection-search-input {
  height: 50px !important;
}

.ant-dropdown-reset-2 .ant-select-selection-placeholder {
  font-weight: normal !important;
}

.custom-dropdown .ant-select-selector {
  height: 48px !important;
}

.ant-text-reset {
  background-color: #f3f6f9 !important;
  border: 0 !important;
  height: 50px !important;
  align-items: center;
}

.ant-text-reset .ant-input{
  background-color: #f3f6f9 !important;

}

.ant-text-reset .ant-input-clear-icon {
  color: #000 !important;
}

.ant-dropdown-reset-bg .ant-select-selector{
  height: 48px !important;
  align-items: center;
}

//AntD <Select /> Overide Styles End

//AntD <Table /> Overide Styles Start

.ant-table-cell::before{
  display: none;
}

.ant-table-wrapper .ant-table-thead >tr>th {
  background-color: unset;
}

//AntD <Table /> Overide Styles End

//Ant Tabs Style Override Local START

.ant-tabs-navigational .ant-tabs-nav{
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -10px !important;
  padding-bottom: 10px !important;

  @media screen and (max-width: 992px) {
    padding: 0px 32px;
    margin-top: -5px !important;
  }
}

.ant-tabs-navigational .ant-tabs-nav-list{
  width: 100% ;
  align-items: center!important;
  padding: 0px 2px;
  max-width: 800px;
}

.ant-tabs-navigational .ant-tabs-nav-wrap{
  display: flex ;
  flex-direction: column;
  justify-content: center ;
  align-items: center !important;
  height: 56px;
  width: 100%;
  background-color: white;
  max-width: 800px;
  border-radius: 14px;
  border: 1px solid black;

  @media screen and (max-width: 992px) {
    font-size: 14px !important;
    height: 48px;
  }
}

.ant-tabs-navigational .ant-tabs-tab{
  flex: 1 ;
  justify-content: center;
  text-transform: uppercase;
  height: 50px;
  border-radius: 12px !important;
  background-color: #fff !important;

  @media screen and (max-width: 992px) {
    padding: 0px 32px;
    font-size: 14px !important;
    height: 44px;
  }
}

.ant-tabs-navigational .ant-tabs-tab-active{
  background-color: #000 !important;
  color: white !important;
  border: 0 !important;
}

.ant-tabs-navigational .ant-tabs-tab-active div{
  color: white !important;
}

//Ant Tabs Style Override Local END

.card-default-controller .ant-card-body {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-default-controller {
  border-radius: 12px;
  height: 100%;
  cursor: pointer;
  overflow: hidden;

}



//Ant Tabs Style Override Local START

.ant-tabs-portfolio .ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-portfolio .ant-tabs-nav-list {
  width: 100%;
  align-items: center !important;
  padding: 0px 2px;
}

.ant-tabs-portfolio .ant-tabs-nav-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  height: 56px;
  width: 100%;
  background-color: white;
  border-radius: 14px;
  border: 1px solid black;

  @media screen and (max-width: 992px) {
      font-size: 10px !important;
      height: 48px;
  }
}

.ant-tabs-portfolio .ant-tabs-tab {
  flex: 1;
  justify-content: center;
  text-transform: uppercase;
  height: 50px;
  border-radius: 12px !important;
  background-color: #fff !important;
}

.ant-tabs-portfolio .ant-tabs-tab-active {
  background-color: #000 !important;
  color: white !important;
  border: 0 !important;
}

.ant-tabs-portfolio .ant-tabs-tab-active div {
  color: white !important;
}

//Ant Tabs Style Override Local END

.ant-tabs-reset .ant-tabs-nav-list {
  width: unset;
  align-items: unset;
}

.ant-tabs-reset .ant-tabs-nav-wrap {
  display: unset !important;
  height: unset !important;
  border: unset !important;
  border-radius: unset !important;

}

.ant-tabs-reset .ant-tabs-tab {
  flex: unset !important;
  text-transform: unset !important;
  height: unset !important;
  background-color: unset !important;
  color: unset !important;
  font-size: 15px !important;
}

.ant-tabs-reset .ant-tabs-tab-active {
  border: unset !important;
}

.ant-tabs-reset .ant-tabs-tab-active div {
  color: unset !important;
}

.custom-tab-profile {

  & .ant-tabs-nav-wrap{
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    // width: 100%;
  }

  & .ant-tabs-nav-list{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
  }

  & .ant-tabs-tab {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    font-size: 16px;
    font-weight: 500;
  }
}

.ant-upload-drag-container{
  padding: 32px;
}

.ant-qrcode{
  margin: auto;
}

.ant-form-item-explain-error {
  text-align: left;
}

.ant-popover{
  z-index: 201;
}

.financial-collapse .ant-collapse-content-box {
  padding: 0px 10px !important;
}

.trans-ant-nav .ant-tabs-nav{
  background-color: #f5f5f5;
  position: sticky;
  top: 56px;
  z-index: 300;
}

.wallet-card .ant-card-body{
  padding: 0;
}

.wallet-tabs .ant-tabs-tab{
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 17px;
  height: fit-content;
}

.wallet-tabs .ant-tabs-tab-active{
  background-color: black;
  padding: 10px 20px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 17px;

  & .ant-tabs-tab-btn{
    color: white !important;
  }
}

.wallet-tabs .ant-tabs-nav{
  margin: 0 0 0px 0px;
}

.wallet-tabs .ant-tabs-tab-btn-active{
  color: white;
}

.ant-form-item-required::before{
  display: none !important;
 }

.ant-form-item-required::after{
  display: inline-block !important;
  margin-inline-end: 4px !important;
  color: #ff4d4f !important;
  font-size: 14px !important;
  font-family: SimSun,sans-serif !important;
  line-height: 1 !important;
  content: " *" !important;
}

.ant-menu-item {
  padding-top: 4px;
}

.custom-collapse {
  h5 {
    margin: 4px 0 0px 0!important;
    font-size: 14px;
    margin-top: 10px;
  }

  ul {
    margin-top: 10px;
    padding-left: 16px;
  }
}

.custom-collapse .ant-collapse-header {
  padding-bottom: 12px !important;
}

.custom-collapse .ant-collapse-content-box {
  background-color: #f8f8f8;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.custom-collapse .ant-collapse-content {
  border-top: none !important;
}

.ant-timeline-item-head {
  background-color: transparent !important;
}

.slick-dots {
  bottom: 20px !important;
  button {
    width: 8px !important;
    height: 8px !important;
    border-radius: 32px !important;
    background-color: #575757 !important;
    z-index: 1 !important;
  }

  .slick-active {
    width: 100%;
    button {
      box-shadow: 0px 2px 6px #0000003f !important;
      border-radius: 32px !important;
      width: 24px !important;
    }
  }
}

.ant-notification-notice-error{
  background-color: #FCE5E4 !important;
}

.ant-notification-notice-info{
  background-color: #E3ECFE !important;
}

.ant-notification-notice-warning{
  background-color: #FDF3E3 !important;
}

.ant-notification-notice-success{
  background-color: #EAF6E2 !important;
}


.slider .ant-form-item-control-input-content{
  background-color: #f3f6f9 !important;
  padding: 0px 24px;
  border-radius: 9px;
  height: 50px;
}

.slider .ant-slider-track {
  background-color: $selectedGreen !important;
}

.slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #fff;
  background-color: $selectedGreen;
}

.slider .ant-slider-rail {
  background-color: black !important;
}

.slider .ant-slider-mark-text {
  font-weight: 400 !important;
  font-size: 12px !important;
  margin-top: 2px !important;
}

.slider .ant-slider-dot {
  border: 2px solid #000;
}

.file-uploader .ant-divider {
  display: flex;
  justify-content: center !important;
}

.file-uploader .ant-upload {
  border: 1px dashed #B1B1B1 !important;
  border-radius: 10px;
}

.file-uploader  .ant-upload-drag {
  border: none !important;
}

.custom-divider::after, .custom-divider::before {
  background-color: #939598;
}

// .custom-timeline .ant-timeline-item-tail {
  // background-color: #acacac !important;
  // filter: brightness(105%) !important;
// }

.custom-stepper .ant-steps-item-content{
  font-weight: 600;
}

.custom-stepper .ant-steps-icon {
  font-weight: 600;
}

.custom-stepper .ant-steps-item-finish {
  .ant-steps-item-icon {
    background-color: $selectedGreen !important;
    border: none !important;
  }

  .ant-steps-item-title{
    color: #8B8B8B !important;
  }
}

.custom-stepper .ant-steps-item-icon {
  background-color: #575757 !important;

  span{
    color: white !important; 
  }
}

.custom-stepper .ant-steps-item .ant-steps-item-title::after{
  background-color: #cfcfcf !important;
}

.custom-stepper .ant-steps-item-active .ant-steps-item-title::after{
  background-color: #000 !important;
}

.custom-stepper .ant-steps-item-active{
  .ant-steps-item-icon {
    background-color: #000 !important;
  }
}
.filter-form {
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0px;
}

.filter-form .ant-form-item-no-colon {
  font-weight: normal;
}

.filter {
  transform: translateY(6px);
  z-index: 10;
  flex: 1;
}

@media screen and (min-width: 1200px) {
  .filter-form-item .ant-form-item-no-colon{
    font-size: 12px !important;
    overflow: hidden !important;
    cursor: pointer !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
  }
 
}


@media screen and (max-width: 1200px) {
  .filter-form-item .ant-form-item-label {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }

  .filter-form-item .ant-form-item-no-colon {
    width: 100% !important;
    flex: 1;
  }

  .filter-form-item .ant-row .ant-col:first-child{
    width: 100%;
  }
}

.detail-card .ant-card-head {
  border-bottom: none !important;
}

.rate-card .ant-card-body {
  padding: 0px !important;
}

.rate-card .ant-skeleton-content {
  padding: 20px !important;
}

//Ant progress bar css start

.progress-bar .ant-progress {
  margin: 0px !important;
}

.progress-bar .ant-progress-inner{
  background-color: black !important;
  height: 10px !important;
}

.progress-bar .ant-progress-bg{
  height: 10px !important;
}

//Ant progress bar css End

.tms .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $secondaryGreen;
  border-color: $secondaryGreen;
}

.forgot-card .ant-card-body {
  padding: 0 !important;
}

.login-input .ant-input{
  border-color: $grey !important;
  height: 50px;
}

.login-form .ant-form-item-required::after{ 
  display: none !important;
}

.password-input .ant-input-password {
  border-color: $grey !important;
}

.ant-tooltip {
  white-space: pre-line;
}

.detail-input .ant-picker-input {
  font-family: 'Ambit', sans-serif !important;
}

.profile-form .ant-picker-input {
  font-family: 'Ambit', sans-serif !important;
}

.profile-select .ant-select-selector{
  height: 48px !important;

  input {
    height: 100% !important;
  }

  .ant-select-selection-placeholder {
    height: 100% !important;
    line-height: 46px !important;
  }

  .ant-select-selection-item {
    height: 100% !important;
    line-height: 46px !important;
  }
}

.ant-form-item-required::before{
  display: none !important;
 }
 
 .login-form .ant-form-item-required::after{ 
   display: none !important;
 }

.email-card .ant-card-body {
  width: 100%;
}

.ant-menu {
  border-inline-end: none !important;
}

.ant-menu-light {
  @media screen and (min-width: $ipadWidthBreakpoint) {
    color: #fff!important;
    background: $primary !important;
  }
}

.ant-menu-item-selected {
  color: #fff!important;
  background: $primary !important;

  @media screen and (max-width: $ipadWidthBreakpoint) {
    color: #fff!important;
    background: $primary !important;
  }
}

.ant-menu-item-active {
  background-color: $sysGrey !important;
  color: $primary !important;
}

.location-selector {

  .ant-select-focused {

    .ant-select-selector {
      background-color: transparent !important;
      color: white !important;
      padding: 0 !important;
      box-shadow: none !important;
    }

    .ant-select-selection-item {
      font-weight: 700 !important;
      font-size: 15px !important;
      margin-right: 10px;
      color: white !important;
      border: none !important;
      outline: none !important;
    }
  }

  .ant-select-selector{
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    padding: 0 !important;
    // width: 200px !important;
  }

  .ant-select-selection-item {
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-right: 10px;
  }

  .ant-select-arrow {
    top: 11px;
    svg {
      path {
        fill: white;
      }
    }
  }

}

.login-modal .ant-modal-content {
  min-height: 200px;
}

.search-input {
  width: calc(100% - 88px) !important;

  .ant-input {
    max-width: 500px;
    height: 32px !important;
  }
}

.t-list-controller {
  .ant-card {
    background-color: #4D538E;
    backdrop-filter: blur(50px);
  }

  .ant-card-actions {
    background-color: #4D538E;
    backdrop-filter: blur(50px);
    border-top: none;
  }
}

.t-list {
  position: relative;
  .ant-card-body {
    padding: 10px;
  }

  .ant-card-meta-title {
    font-size: 15px !important;
    font-weight: 700 !important;
    margin-bottom: 0 !important;
    color: white !important;
  }

  .ant-card-meta-description {
    color: $sysGrey !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    text-transform: capitalize;
    color: $grey !important;
  }

  .ant-card-actions  > li{
    margin: 0 !important;
  }
}

.detail-collapse .ant-collapse-header {
  background-color: white !important;
  border-radius: 10px !important;
}

.detail-collapse .ant-collapse-item-active {
  .ant-collapse-header {
    background-color: white !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}


.detail-collapse .ant-collapse-content {
  background-color: white !important;
}

.mobile-drawer .ant-drawer-body {
  display: flex;
  flex-direction: column;
}

.ant-image-preview-body {
  background-color: #000000e4;
}

.ant-image-preview-operations {
  padding-right: 10px;
}

.custom-carousel .slick-dots {
  bottom: -20px !important;
}
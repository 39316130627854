@import './vars';
[data-theme="light"] {
  background-color:$primary ;

  .bg-1,
  .bg-2 {
    background-color: $primary;
  }

  .ant-layout-sider {
    background-color: $primary;
  }

  .text-1 {
    color: #000;
  }

  .text-2 {
    color: rgba(0, 0, 0, .85);
  }
}

[data-theme="dark"] {
  .bg-1 {
    background-color: #000;
  }

  .bg-2 {
    background-color: #1f1f1f;
  }

  .text-1 {
    color: #fff;
  }

  .text-2 {
    color: rgba(255, 255, 255, .65);
  }
}
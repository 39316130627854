@import '../../styles/vars';

// .collection-layout {
  // background-color: blue;
// }

.header {
  background-color: white;
  display: flex;
  align-items: center;
  gap: 6px;
  overflow-x: auto;
  position: sticky;
  top: 64px;
  z-index: 200;
  
}

.header::-webkit-scrollbar{
  display: none;
}

.max-width {
  @include max-width-controller();
  width: 100%;
  padding: 10px;
}

.custom-tag {
  cursor: pointer;
  border-radius: 14px;
  font-size: 14px;
  padding: 8px 12px;
  font-weight: 500;

  &:hover {
    background-color: $grey;
    transition: 0.2s
  }

  &-active {
    @extend .custom-tag;
    background-color: $primary;
    color: white;
    border: none;

    &:hover {
      background-color: $primary;
      color: white;
    }
  }

}

.collection-controller {
  @include max-width-controller();
  width: 100%;
  padding-top: 10px;
}

.max-width-c {
 display: flex;
 justify-content: center;
}

.card-image {
  background-color: $sysGrey;
  object-fit: cover;
  height: 230px;
}

.actions {
  text-align: left;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 4px;
}

.quantity {
  width: fit-content;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid $grey;

  align-items: center;
  display: flex;
  gap: 4px !important;

  color: white;
  font-weight: 500;

  span {
    color: white;
    font-size: 11px;
  }
  svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.footer {
  margin-top: -4px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.price {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 2px;

  h4 {
    font-size: 20px;
    margin: 0 !important;
  }

  span {
    font-size: 11px;
  }
}

.type {
  font-size: 15px;
  color: $sysGrey;
}

.primary-attributes {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.heading-manage {
  // background-color: red;
  padding: 16px 10px;

  h4 {
    margin-bottom: 0 !important;
  }
}

.no-data {
  display: flex;
  height: calc(100vh - 64px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.touch-controller {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.title-controller {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    // z-index: 3;
    width: 18px;
    height: 18px;
  }
}